import React, { useState } from "react";

import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import { usePrevious } from "../../hooks/usePrevious";
import * as datasetService from "../../services/datasetService";

import InputPhone from "../input-phone/input-phone.component";
import RadioContactType from "../radio-contact-type/radio-contact-type.component";
import RadioSenderType from "../radio-sender-type/radio-sender-type.coponent";

const CreatePostPersonalDetails = (props) => {
  const {
    register,
    errors,
    unregister,
    dictionary,
    watch,
    getValues,
    setValue,
    triggerValidation,
    hasVideo,
    contactTypes,
    setCurrentContactType,
    currentContactType
  } = props;

  const forumConfiguration = datasetService.getForumConfiguration();

  const EMAIL_INPUT_NAME = "email";
  const PHONE_INPUT_NAME = "mobil";

  const watchSenderTypeId = watch("senderTypeId");
  const prevContactType = usePrevious(currentContactType);

  useUpdateEffect(() => {
    handleSenderTypeUnregister(watchSenderTypeId);
  }, [watchSenderTypeId]);

  useUpdateEffect(() => {
    handleContactTypeUnregister();
  }, [currentContactType]);

  const handleContactTypeUnregister = () => {
    if (currentContactType === contactTypes.EMAIL_CONTACT_TYPE) {
      unregister("phone");
    }
    if (currentContactType !== contactTypes.EMAIL_CONTACT_TYPE) {
      unregister("email");
    }
  };

  const handleSenderTypeUnregister = (senderTypeId) => {
    if (senderTypeId === "1") {
      unregister("organization");
      unregister("personTitle");
      unregister("placeOfEducation");
      unregister("holdClass");
      unregister("FilmCompetition");
    }
    if (senderTypeId === "2") {
      unregister("age");
      unregister("zipcode");
      unregister("placeOfEducation");
      unregister("holdClass");
      unregister("FilmCompetition");
    }
    if (senderTypeId === "3") {
      unregister("age");
      unregister("zipcode");
      unregister("organization");
      unregister("personTitle");
    }
  };
  return (
    <div className="forum-create-post__personal-details">
      <div className="forum-create-post__input-group">
        <RadioSenderType
          dictionary={dictionary}
          watchSenderTypeId={watchSenderTypeId}
          register={register}
          setValue={setValue}
          forumConfiguration={forumConfiguration}
        />
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-6">
            <input
              className={errors.name ? "input--error" : ""}
              {...register("name", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Firstname,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.Firstname}
              placeholder={dictionary.Placeholders.Firstname}
              autoComplete="given-name"
            />
            {errors.name && (
              <div className="input-error-message">{errors.name.message}</div>
            )}
          </div>
          <div className="forum-input-col col-sm-6">
            <input
                className={errors.lastname ? "input--error" : ""}
              {...register("lastname", {
                required: {
                  value: true,
                  message: dictionary.Errors.Required.Lastname,
                  maxLength: 100,
                },
              })}
              type="text"
              aria-label={dictionary.Placeholders.Lastname}
              placeholder={dictionary.Placeholders.Lastname}
              autoComplete="family-name"
            />
            {errors.lastname && (
              <div className="input-error-message">
                {errors.lastname.message}
              </div>
            )}
          </div>
        </div>
        {watchSenderTypeId == 3 && (
          <div className="forum-input-row row">
            <div className="forum-input-col col-sm-6">
              <input
                className={errors.placeOfEducation ? "input--error" : ""}
                {...register("placeOfEducation", {
                  required: {
                    value: true,
                    message: dictionary.Errors.Required.PlaceOfEducation,
                    maxLength: 100,
                  },
                })}
                type="text"
                aria-label={dictionary.Placeholders.PlaceOfEducation}
                placeholder={dictionary.Placeholders.PlaceOfEducation}
                autoComplete="education organization"
              />
              {errors.placeOfEducation && (
                <div className="input-error-message">
                  {errors.placeOfEducation.message}
                </div>
              )}
            </div>
            <div className="forum-input-col col-sm-6">
              <input
                className={errors.holdClass ? "input--error" : ""}
                {...register("holdClass", {
                  required: {
                    value: true,
                    message: dictionary.Errors.Required.HoldClass,
                  },
                })}
                type="text"
                aria-label={dictionary.Placeholders.HoldClass}
                placeholder={dictionary.Placeholders.HoldClass}
                autoComplete="education organization-title"
              />
              {errors.holdClass && (
                <div className="input-error-message">
                  {errors.holdClass.message}
                </div>
              )}
            </div>
          </div>
        )}
        {forumConfiguration.IncludeVideo && watchSenderTypeId == 3 && (
          <div className="forum-input-row row">
            <div className="forum-input-col col-sm-12">
              <label
                htmlFor="accept-film-competition"
                className={`checkbox__container accept-movie-competition ${
                  hasVideo() ? "" : "disabled"
                }`}
              >
                <span className="check-label">
                  {dictionary.Labels.FilmCompetition}
                </span>
                <span className="custom-checkbox">
                  <input
                    {...register("FilmCompetition")}
                    type="checkbox"
                    aria-label={dictionary.Labels.FilmCompetition}
                    id="accept-film-competition"
                    disabled={!hasVideo()}
                  />
                  <span className="icon"></span>
                </span>
              </label>
            </div>
          </div>
        )}
        {watchSenderTypeId == 2 && (
          <div className="forum-input-row row">
            <div className="forum-input-col col-sm-6">
              <input
                className={errors.organization ? "input--error" : ""}
                {...register("organization", {
                  required: {
                    value: true,
                    message: dictionary.Errors.Required.Organization,
                    maxLength: 100,
                  },
                })}
                type="text"
                aria-label={dictionary.Placeholders.Organization}
                placeholder={dictionary.Placeholders.Organization}
                autoComplete="organization"
              />
              {errors.organization && (
                <div className="input-error-message">
                  {errors.organization.message}
                </div>
              )}
            </div>
            <div className="forum-input-col col-sm-6">
              <input
                className={errors.personTitle ? "input--error" : ""}
                {...register("personTitle", {
                  required: {
                    value: true,
                    message: dictionary.Errors.Required.PersonTitle,
                  },
                })}
                type="text"
                aria-label={dictionary.Placeholders.PersonTitle}
                placeholder={dictionary.Placeholders.PersonTitle}
                autoComplete="organization-title"
              />
              {errors.personTitle && (
                <div className="input-error-message">
                  {errors.personTitle.message}
                </div>
              )}
            </div>
          </div>
        )}
        {watchSenderTypeId == 1 && (
          <div className="forum-input-row row">
            <div className="forum-input-col col-sm-6">
              <input
                className={errors.age ? "input--error" : ""}
                {...register("age", {
                  required: {
                    value: true,
                    message: dictionary.Errors.Required.Age,
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: dictionary.Errors.Age,
                  },
                })}
                type="text"
                aria-label={dictionary.Placeholders.Age}
                placeholder={dictionary.Placeholders.Age}
              />
              {errors.age && (
                <div className="input-error-message">{errors.age.message}</div>
              )}
            </div>
            <div className="forum-input-col col-sm-6">
              <input
                className={errors.zipcode ? "input--error" : ""}
                {...register("zipcode", {
                  required: {
                    value: true,
                    message: dictionary.Errors.Required.Zipcode,
                  },
                })}
                type="text"
                aria-label={dictionary.Placeholders.Zipcode}
                placeholder={dictionary.Placeholders.Zipcode}
                autoComplete="postal-code"
              />
              {errors.zipcode && (
                <div className="input-error-message">
                  {errors.zipcode.message}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="forum-create-post__input-group">
        <RadioContactType
          includeInternationalPhone={
            forumConfiguration.IncludeInternationalPhone
          }
          currentContactType={currentContactType}
          setCurrentContactType={setCurrentContactType}
          contactTypes={contactTypes}
          dictionary={dictionary}
        />
        <div className="forum-input-row row">
          {currentContactType === contactTypes.EMAIL_CONTACT_TYPE && (
            <div className="forum-input-col col-md-6">
              <input
                className={errors.email ? "input--error" : ""}
                {...register(EMAIL_INPUT_NAME, {
                  required: {
                    value: true,
                    message: dictionary.Errors.Required.Email,
                    minLength: 8,
                    maxLength: 8,
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: dictionary.Errors.Email,
                  },
                })}
                type="email"
                aria-label={dictionary.Placeholders.ContactEmail}
                placeholder={dictionary.Placeholders.ContactEmail}
                autoComplete="email"
              />
              {errors.email && (
                <div className="input-error-message">
                  {errors.email.message}
                </div>
              )}
            </div>
          )}
          {currentContactType === contactTypes.DANISH_CONTACT_TYPE && (
            <InputPhone
              errors={errors}
              register={register}
              dictionary={dictionary}
              name={PHONE_INPUT_NAME}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              contactTypes={contactTypes}
              currentContactType={currentContactType}
              triggerValidation={triggerValidation}
              prevContactType={prevContactType}
            />
          )}
          {currentContactType === contactTypes.INTERNATIONAL_CONTACT_TYPE && (
            <InputPhone
              errors={errors}
              register={register}
              dictionary={dictionary}
              name={PHONE_INPUT_NAME}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              contactTypes={contactTypes}
              currentContactType={currentContactType}
              triggerValidation={triggerValidation}
              prevContactType={prevContactType}
            />
          )}
        </div>
      </div>
      <div className="forum-create-post__input-group">
        <div className="forum-input-row row">
          <div className="forum-input-col col-sm-12">
            <label
              htmlFor="accept-terms"
              className={`checkbox__container accept-terms ${
                errors.terms ? "input--error" : ""
              }`}
            >
              <span
                className="check-label"
                dangerouslySetInnerHTML={{
                  __html: dictionary.Labels.AcceptTermsOfConditions,
                }}
              />
              <span className="custom-checkbox">
                <input
                  {...register("terms", {
                    required: {
                      value: true,
                      message: dictionary.Errors.AcceptTermsOfConditions,
                    },
                  })}
                  type="checkbox"
                  aria-label={dictionary.Labels.AcceptTermsOfConditions}
                  id="accept-terms"
                  tabIndex={0}
                />
                <span className="icon"></span>
              </span>
            </label>
            {errors.terms && (
              <div className="input-error-message">{errors.terms.message}</div>
            )}
          </div>
        </div>
        <div className="forum-input-row row">
          <div className="forum-input-col col-md-12">
            <p>{dictionary.Labels.RequiredFieldText}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreatePostPersonalDetails;
