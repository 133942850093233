let apiUrl = null;
let siteId = null;
let dictionary = null;
let categories = null;
let captchaMetaData = null;
let forumConfiguration = null;
const forumCreatePostElement = document.getElementById('forum-create-post');

const getApiUrl = () => {
    if(apiUrl === null) setApiUrl();
    return apiUrl;
}

const setApiUrl = () => {
    if(forumCreatePostElement) apiUrl = forumCreatePostElement.dataset.apiurl;  
}

const getSiteId = () => {
    if (siteId === null) setSiteId();
    return siteId;
}

const setSiteId = () => {
    if (forumCreatePostElement) siteId = forumCreatePostElement.dataset.siteid;
}

const getDictionary = () => {
    if(dictionary === null) setDictionary();
    return dictionary;
}

const setDictionary = () => {
    if(forumCreatePostElement) dictionary = JSON.parse(forumCreatePostElement.dataset.dictionary);  
}

const getCategories = () => {
  if(categories == null) setCategories();
  return categories;
}

const setCategories = () => {
  if(forumCreatePostElement) categories = JSON.parse(forumCreatePostElement.dataset.categories);
}

const getCaptchaMetaData = () => {
  if(captchaMetaData === null) setCaptchaMetaData();
  return captchaMetaData;
}

const setCaptchaMetaData = () => {
  if(forumCreatePostElement) captchaMetaData = JSON.parse(forumCreatePostElement.dataset.captchametadata)
}

const getForumConfiguration = () => {
  if(forumConfiguration == null) setForumConfiguration();
  return forumConfiguration;
}

const setForumConfiguration = () => {
  if(forumCreatePostElement) forumConfiguration = JSON.parse(forumCreatePostElement.dataset.forumconfiguration);
}

export { getApiUrl, getSiteId, getDictionary, getCategories, getCaptchaMetaData, getForumConfiguration }
