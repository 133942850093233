import React, { useEffect } from "react";

const RadioSenderType = (props) => {
    const { dictionary, watchSenderTypeId, register, setValue, forumConfiguration } = props;

    // Default to the first available option
    useEffect(() => {
        if (watchSenderTypeId === undefined || watchSenderTypeId === null) {
            if (forumConfiguration.IncludeMyself) {
                setValue("senderTypeId", 1);
            } else if (forumConfiguration.IncludeOrganization) {
                setValue("senderTypeId", 2);
            } else if (forumConfiguration.IncludeEducation) {
                setValue("senderTypeId", 3);
            }
        }
    }, [watchSenderTypeId, forumConfiguration, setValue]);

    const numberOfOptionsEnabled =
        [forumConfiguration.IncludeMyself, forumConfiguration.IncludeOrganization, forumConfiguration.IncludeEducation]
            .filter(option => option).length;

    // Return false if only one option is true
    const shouldRenderRadioButtons = numberOfOptionsEnabled > 1;

  return (
    <div className="forum-input-row row">
      <div className="forum-input-col col-sm-12">
        <p>{dictionary.Labels.SenderType}</p>
              {shouldRenderRadioButtons && (
                  <div
                      className="radio-buttons"
                      role="radiogroup"
                      aria-label="senderType"
                  >
                      {forumConfiguration.IncludeMyself && (
                          <label
                              className={`radio-button__container 
              ${watchSenderTypeId == 1 ? "radio--selected" : ""}`}
                          >
                              <span className="check-label">
                                  {dictionary.Labels.PrivatePerson}
                              </span>
                              <span className="custom-radio">
                                  <input
                                      {...register("senderTypeId")}
                                      value={1}
                                      aria-label={dictionary.Labels.PrivatePerson}
                                      type="radio"
                                      checked={watchSenderTypeId == 1}
                                      tabIndex={0}
                                  />
                                  <span className="icon"></span>
                              </span>
                          </label>
                      )}
                      {forumConfiguration.IncludeOrganization && (
                          <label
                              className={`radio-button__container 
                        ${watchSenderTypeId == 2 ? "radio--selected" : ""}`}
                          >
                              <span className="check-label">
                                  {dictionary.Labels.Organization}
                              </span>
                              <span className="custom-radio">
                                  <input
                                      {...register("senderTypeId")}
                                      value={2}
                                      aria-label={dictionary.Labels.Organization}
                                      type="radio"
                                      checked={watchSenderTypeId == 2}
                                      tabIndex={0}
                                  />
                                  <span className="icon"></span>
                              </span>
                          </label>
                      )}
                      {forumConfiguration.IncludeEducation && (
                          <label
                              className={`radio-button__container 
                ${watchSenderTypeId == 3 ? "radio--selected" : ""}`}
                          >
                              <span className="check-label">{dictionary.Labels.Education}</span>
                              <span className="custom-radio">
                                  <input
                                      {...register("senderTypeId")}
                                      value={3}
                                      type="radio"
                                      aria-label={dictionary.Labels.Education}
                                      checked={watchSenderTypeId == 3}
                                      tabIndex={0}
                                  />
                                  <span className="icon"></span>
                              </span>
                          </label>
                      )}
                  </div>
              )}
      </div>
    </div>
  );
};

export default RadioSenderType;
