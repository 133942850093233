export default function Loading() {
  return (
    <div className="loading" role="status" aria-label="Siden arbejder, vent venligst">
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
      <div className="container">
        <span className="object"></span>
      </div>
    </div>
  );
}
