import React from "react";

const RadioContactType = (props) => {
  const {
    currentContactType,
    setCurrentContactType,
    contactTypes,
    dictionary,
    includeInternationalPhone
  } = props;

  const radioName = "contactType";

  const handleOnChange = (event) => {
    setCurrentContactType(event.target.value);
  };

  return (
    <div className="forum-input-row row">
      <div className="forum-input-col col-sm-12">
        <p>{dictionary.Labels.ContactType}</p>
        <div
          className="radio-buttons"
          role="radiogroup"
          aria-label="contactType"
        >
          <label
            className={`radio-button__container 
              ${
                currentContactType == contactTypes.EMAIL_CONTACT_TYPE
                  ? "radio--selected"
                  : ""
              }`}
          >
            <span className="check-label">{dictionary.Labels.Email}</span>
            <span className="custom-radio">
              <input
                name={radioName}
                value={contactTypes.EMAIL_CONTACT_TYPE}
                type="radio"
                aria-label={dictionary.Labels.Email}
                checked={currentContactType == contactTypes.EMAIL_CONTACT_TYPE}
                tabIndex={0}
                onChange={handleOnChange}
              />
              <span className="icon"></span>
            </span>
          </label>
          <label
            className={`radio-button__container 
              ${
                currentContactType == contactTypes.DANISH_CONTACT_TYPE
                  ? "radio--selected"
                  : ""
              }`}
          >
            <span className="check-label">{dictionary.Labels.Phone}</span>
            <span className="custom-radio">
              <input
                name={radioName}
                value={contactTypes.DANISH_CONTACT_TYPE}
                type="radio"
                aria-label={dictionary.Labels.Phone}
                checked={currentContactType == contactTypes.DANISH_CONTACT_TYPE}
                tabIndex={0}
                onChange={handleOnChange}
              />
              <span className="icon"></span>
            </span>
          </label>
          {includeInternationalPhone && 
            <label
              className={`radio-button__container 
                ${
                  currentContactType == contactTypes.INTERNATIONAL_CONTACT_TYPE
                    ? "radio--selected"
                    : ""
                }`}
            >
              <span className="check-label">
                {dictionary.Labels.InternationalPhone}
              </span>
              <span className="custom-radio">
                <input
                  name={radioName}
                  value={contactTypes.INTERNATIONAL_CONTACT_TYPE}
                  type="radio"
                  aria-label={dictionary.Labels.InternationalPhone}
                  checked={
                    currentContactType == contactTypes.INTERNATIONAL_CONTACT_TYPE
                  }
                  tabIndex={0}
                  onChange={handleOnChange}
                />
                <span className="icon"></span>
              </span>
            </label>          
          }
        </div>
      </div>
    </div>
  );
};

export default RadioContactType;
